.columnLayout {
	@apply
	yst-grid
	yst-grid-cols-1
	lg:yst-grid-cols-2
	yst-max-w-[1024px]
	yst-gap-6
	lg:yst-gap-10
	yst-min-h-[546px];
}

.firstColumn {
	@apply
	yst-flex
	yst-flex-col
	yst-gap-4
	yst-items-center
	yst-justify-center
	yst-text-center;

	img:nth-of-type(1) {
		@apply
		yst-mt-6
		lg:yst-mt-0
		yst-w-11/12
		md:yst-w-5/6;
	}

	img:nth-of-type(2) {
		@apply
		yst-hidden
		lg:yst-block
		yst-w-40
		yst-h-40;
	}

	h1, p {
		@apply
		yst-hidden
		lg:yst-block
		yst-w-5/6;
	}
}

.secondColumn {}

.linkColumn {
	@apply
	yst-col-span-full
	yst-text-right;
}
