@use "../styles.scss";
@use "../../../config/papers.scss";
@use "../../../config/defaults.scss" as defaults;

.layoutLogin {
  background: var(--bg-color-purple) url( https://my.yoast.com/cdn-cgi/image/width=1920%2Cquality=85fit=crop%2Cf=auto%2Conerror=redirect/login-background.jpg ) no-repeat 100% 0;
  background-size: cover;
  color: #000;

  display: flex;
  min-height: 100%;

  align-items: center;
  justify-content: center;
  padding: var(--gap);
}

.whitePaper {
  @apply yst-rounded-md;
  @extend .whitePage;
  margin: auto;
  padding: 48px;

  @media screen and (max-width: defaults.$mediumBreakpoint) {
	width: 100%;
	max-width: 520px;
	padding: 24px;
  }

  @media screen and ( max-width: defaults.$mobileBreakpoint ) {
    width: 100%;
  }
}
